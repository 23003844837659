import React, {Component} from "react";
// @material-ui/core components

// core components
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class Connect extends Component{
    constructor(props) {
        super(props);
        this.state = {};

        this.showResults = this.showResults.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let params = {};
        params[`${event.target.id}`] = event.target.value;
        this.setState(params);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.showResults(this.state);
    }

    showResults(values){
        console.log(values);
        fetch('https://gamapi.com/api/login', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res =>{
                res.text().then(text => {
                    localStorage.setItem('user', text);
                });
                console.log(res);
                window.location = '/home';
            })
            .catch(() => {
                console.log('fail');
            });
    };

    render(){
        const classes = makeStyles(styles);
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Connect to GamApi</h4>
                                    <p>{ this.props.errorMessage }</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Login"
                                                id="login"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    value: this.state.login,
                                                    onChange: this.handleChange
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                inputType="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    value: this.state.password,
                                                    onChange: this.handleChange
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" color="primary" >Login</Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        );
    }
}

export default Connect;
import React, {Component} from 'react';
import CustomInput from "../CustomInput/CustomInput";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

class SubscriptionForm extends Component{
    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let params = {};
        params[`${event.target.id}`] = event.target.value;
        this.setState(params);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state);
    }

    render(){
        const classes = makeStyles(styles);
        return (
            <form onSubmit={this.handleSubmit}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Become a Gamapi developer</h4>
                                <p className={classes.cardCategoryWhite}>Complete information</p>
                                <p>{ this.props.errorMessage }</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Email address"
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true,
                                                value: this.state.email,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Username"
                                            id="login"
                                            formControlProps={{
                                                fullWidth: true,
                                                value: this.state.username,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Password"
                                            id="password"
                                            inputType="password"
                                            formControlProps={{
                                                fullWidth: true,
                                                value: this.state.email,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="First Name"
                                            id="firstname"
                                            formControlProps={{
                                                fullWidth: true,
                                                value: this.state.firstname,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Last Name"
                                            id="lastname"
                                            formControlProps={{
                                                fullWidth: true,
                                                value: this.state.lastname,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" color="primary" >Create a developer account !</Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }
}

export default SubscriptionForm;
import React, {Component} from "react";
// @material-ui/core components

// core components
import SubscriptionForm from "components/SubscriptionForm/SubscriptionForm.js";

class Subscribe extends Component{
    constructor(props) {
        super(props);
        this.state = {};

        this.showResults = this.showResults.bind(this);
    }

    showResults(values){
        console.log(values);
        fetch('https://gamapi.com/api/register', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res =>{
                if (res.status === 200){
                    console.log('Successful subscription')
                } else {
                    res.text().then(text => {
                        this.setState({ apiError: text });
                    });
                }
                window.location = '/login';
                console.log(res);
            })
            .catch(() => {
                console.log('fail');
            });
    };

    render(){
        return (
            <div>
                <SubscriptionForm onSubmit={this.showResults} errorMessage={this.state.apiError}/>
            </div>
        );
    }
}

export default Subscribe;
export default function ParsedRoutes(group) {
    const routes = [
        {
            "type": "post",
            "url": "/api/login",
            "title": "Request a token",
            "name": "Login",
            "group": "Auth",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "login",
                            "description": "<p>User login</p>"
                        },
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "password",
                            "description": "<p>User password.</p>"
                        }
                    ]
                }
            },
            "success": {
                "fields": {
                    "Success 200": [
                        {
                            "group": "Success 200",
                            "type": "String",
                            "optional": false,
                            "field": "token",
                            "description": "<p>A unique token to be authenticated on the API, must be set in the header as 'X-Auth-Token'.</p>"
                        }
                    ]
                },
                "examples": [
                    {
                        "title": "Success-Response:",
                        "content": "HTTP/1.1 200 OK\n{\n  \"token\": \"1256983pod98d4f6d4f6q8f4s84d\"\n}",
                        "type": "json"
                    }
                ]
            },
            "version": "0.0.0",
            "filename": "routes/auth.js",
            "groupTitle": "Auth"
        },
        {
            "type": "post",
            "url": "/api/register",
            "title": "Create an api client",
            "name": "Register",
            "group": "Auth",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "login",
                            "description": "<p>Your email.</p>"
                        },
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "password",
                            "description": "<p>A complex password.</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/auth.js",
            "groupTitle": "Auth"
        },
        {
            "type": "get",
            "url": "/fortnite/stats/:pseudo/:platform",
            "title": "Get player statistics",
            "name": "Stats",
            "group": "Fortnite",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "pseudo",
                            "description": "<p>Pseudo</p>"
                        },
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "platform",
                            "description": "<p>Can be ps4, pc, xb1</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/fortnite/player.js",
            "groupTitle": "Fortnite"
        },
        {
            "type": "get",
            "url": "/summoners_war/leaderSkills",
            "title": "Get all Leader Skills",
            "name": "Leader_Skills",
            "group": "Summoners_War",
            "version": "0.0.0",
            "filename": "routes/summoners_war/leader-skills.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/leaderSkills/:id",
            "title": "Get one Leader Skill",
            "name": "Leader_Skills",
            "group": "Summoners_War",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "Number",
                            "optional": false,
                            "field": "id",
                            "description": "<p>Leader skill unique ID.</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/summoners_war/leader-skills.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/monsters",
            "title": "Get all monsters",
            "name": "Monsters",
            "group": "Summoners_War",
            "version": "0.0.0",
            "filename": "routes/summoners_war/monsters.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/monsters/:id",
            "title": "Get a monster",
            "name": "Monsters",
            "group": "Summoners_War",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "Number",
                            "optional": false,
                            "field": "id",
                            "description": "<p>Monsters unique ID.</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/summoners_war/monsters.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/monsters/name/:name",
            "title": "Get a monster by its name",
            "name": "Monsters",
            "group": "Summoners_War",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "name",
                            "description": "<p>Monsters name</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/summoners_war/monsters.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/skills",
            "title": "Get all Skills",
            "name": "Skills",
            "group": "Summoners_War",
            "version": "0.0.0",
            "filename": "routes/summoners_war/skills.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/skills",
            "title": "Get one Skill",
            "name": "Skills",
            "group": "Summoners_War",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "Number",
                            "optional": false,
                            "field": "id",
                            "description": "<p>Skills unique ID.</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/summoners_war/skills.js",
            "groupTitle": "Summoners_War"
        },
        {
            "type": "get",
            "url": "/summoners_war/runes/:monsterId",
            "title": "Get runes for a monster",
            "name": "Runes",
            "group": "Summoners_War",
            "parameter": {
                "fields": {
                    "Parameter": [
                        {
                            "group": "Parameter",
                            "type": "String",
                            "optional": false,
                            "field": "monsterId",
                            "description": "<p>Monster unique ID.</p>"
                        }
                    ]
                }
            },
            "version": "0.0.0",
            "filename": "routes/summoners_war/skills.js",
            "groupTitle": "Summoners_War"
        }
    ];

    return routes.filter(r => {
       return r.group.toUpperCase() === group.toUpperCase();
    });
}

import React from "react";
// react plugin for creating charts
// @material-ui/core
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Fortnite from "../../assets/img/fortnite.jpg";
import Forstats from "../../assets/img/bg_fortstats.png";
import SW from "../../assets/img/sw.jpg";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/cardImagesStyles.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
    const classes = useStyles();
    return (
        <div>
            <h2>Applications using GamAPI</h2>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card style={{ width: "20rem" }}>
                        <img
                            className={classes.cardImgTop}
                            data-src="holder.js/100px180/"
                            alt="100%x180"
                            style={{ height: "180px", width: "100%", display: "block" }}
                            src={Fortnite}
                            data-holder-rendered="true"
                        />
                        <CardBody>
                            <h4>Fortnite Stats</h4>
                            <p>
                                Fortnite stats application
                            </p>
                            <a href="https://fortnite-stat.com/" target="_blank" rel="noopener noreferrer">
                                <Button color="info">
                                    Go to the App
                                </Button>
                            </a>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card style={{ width: "20rem" }}>
                        <img
                            className={classes.cardImgTop}
                            data-src="holder.js/100px180/"
                            alt="100%x180"
                            style={{ height: "180px", width: "100%", display: "block" }}
                            src={SW}
                            data-holder-rendered="true"
                        />
                        <CardBody>
                            <h4>Swarbot</h4>
                            <p>
                                Summoners War Discord Bot
                            </p>
                            <a href="https://swarbot.com" target="_blank" rel="noopener noreferrer">
                                <Button color="info">
                                    Go to the App
                                </Button>
                            </a>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card style={{ width: "20rem" }}>
                        <img
                            className={classes.cardImgTop}
                            data-src="holder.js/100px180/"
                            alt="100%x180"
                            style={{ height: "180px", width: "100%", display: "block" }}
                            src={Forstats}
                            data-holder-rendered="true"
                        />
                        <CardBody>
                            <h4>Forstats</h4>
                            <p>
                                Twitch chat bot responding with your Fortnite statistics
                            </p>
                            <a href="https://twitch.fortnite-stat.com/fortstats" target="_blank" rel="noopener noreferrer">
                                <Button color="info">
                                    Go to the App
                                </Button>
                            </a>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

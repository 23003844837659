import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import { Link } from "react-router-dom";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location = '/home';
  };

  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
  }
  let connectComponent = null;
  if (user && user.token){
    connectComponent =  <div className={classes.manager}>
      <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
      >
        <Person className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Profile</p>
        </Hidden>
      </Button>
      <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
      >
        {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                        className={classes.dropdownItem}
                    >
                      <Link to="/profile">
                        Profile
                      </Link>
                    </MenuItem>
                    <MenuItem
                        onClick={handleLogout}
                        className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
        )}
      </Poppers>
    </div>;
  } else {
    connectComponent =  <div>
      <div className={classes.manager}>
          <Link to="/login">
            <Button
                aria-owns={openProfile ? "profile-menu-list-grow" : null}
                className={classes.buttonLink}
                color="primary"
            >
              Login
            </Button>
          </Link>
        </div>
        <div className={classes.manager}>
        <Link to="/become_developer">
        <Button
        aria-owns={openProfile ? "profile-menu-list-grow" : null}
        className={classes.buttonLink}
        color="primary"
            >
            Become a developer
        </Button>
      </Link>
      </div>
    </div>;
  }

  return (
    <div>
      {connectComponent}
    </div>
  );
}

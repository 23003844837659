import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ParsedRoutes from '../../api_data.js';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

function buildParams(route){
    if (!route.parameter){
        return 'None';
    }
    let params = route.parameter.fields.Parameter.map(param => {
       return `${param.field}: ${param.description.replace('<p>', '').replace('</p>', '')}`
    });
    return params.join(', ');
}

export default function ApiRoute() {
    const classes = useStyles();
    const getCurrentRoute = () => {
        let split = window.location.pathname.split('/');
        return split[split.length - 1];
    };
    const endpoints = ParsedRoutes(getCurrentRoute());

    let grouped = [];

    endpoints.forEach(data => {
       if (!grouped[data.name]){
           grouped[data.name] = { endpoints: [], title: `${data.group} API ${data.name}`, group: data.group }
       }
       grouped[data.name].endpoints.push([
           `/api${data.url}`,
           data.type.toUpperCase(),
           buildParams(data),
           'Soon',
       ]);
    });

    console.log(grouped);

    let components = [];

    Object.keys(grouped).forEach(function(key) {
        let val = grouped[key];
        components.push(
            <GridContainer key={key}>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>{key.replace('_', ' ')}</h4>
                            <p className={classes.cardCategoryWhite}>
                                {key.replace('_', ' ')} API {val.group.replace('_', ' ')}
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Route name", "Method", "Parameters", "JSON Response"]}
                                tableData={val.endpoints}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    });
    return components;
}
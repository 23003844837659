import React, {Component} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class UserProfile extends Component{
  constructor(props) {
    super(props);
    let user = null;
    try {
      user = JSON.parse(localStorage.getItem('user'));
      this.state = {inputType: 'password', token: user.token, btnText: 'Show token'};
      this.handleShowToken = this.handleShowToken.bind(this);
    } catch (e) {
    }
  }

  handleShowToken(){
    if (this.state.inputType === 'password'){
      this.setState({ inputType: 'text', btnText: 'Hide token' });
    } else {
      this.setState({ inputType: 'password', btnText: 'Show token' });
    }
  }

  render() {
    const classes = makeStyles(styles);
    return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Your profile</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                          labelText="Your GamAPI token"
                          id="token"
                          inputType={this.state.inputType}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            value: this.state.token
                          }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>Your token must be included in your header's request as X-Auth-Token</p>
                      <p>It must be included in each calls</p>
                      <p>If you have any questions, you can ask help on our <a href="https://discord.gg/ASpfMQP" target="_blank" rel="noopener noreferrer">Discord</a> server.</p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="info" onClick={this.handleShowToken}>{this.state.btnText}</Button>
                  {/*<Button type="submit" color="primary" >Renew token</Button>*/}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default UserProfile;
/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import CodeIcon from '@material-ui/icons/Code';

import HomePage from "views/Home/Home.js";
import ApiRoute from "views/ApiRoute/ApiRoute.js";
import SubscribePage from "views/Subscribe/Subscribe";
import Connect from "views/Connect/Connect";
import UserProfile from "views/UserProfile/UserProfile";
import ReorderIcon from '@material-ui/icons/Reorder';

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: Dashboard,
    component: HomePage,
    layout: "/admin",
    menu: true,
    showOnConnect: true
  },
  {
    path: "/become_developer",
    name: "Become a developer",
    icon: CodeIcon,
    component: SubscribePage,
    layout: "/admin",
    menu: false,
    showOnConnect: false
  },
  {
    path: "/fortnite",
    name: "Fortnite API",
    icon: ReorderIcon,
    component: ApiRoute,
    layout: "/admin",
    menu: true,
    showOnConnect: true
  },
  {
    path: "/summoners_war",
    name: "Summoners War API",
    icon: ReorderIcon,
    component: ApiRoute,
    layout: "/admin",
    menu: true,
    showOnConnect: true
  },
  {
    path: "/login",
    name: "Connect to GamApi",
    icon: "content_paste",
    component: Connect,
    layout: "/admin",
    menu: false,
    showOnConnect: false
  }
  ,
  {
    path: "/profile",
    name: "Profile",
    icon: "content_paste",
    component: UserProfile,
    layout: "/admin",
    menu: false,
    showOnConnect: true
  }
];

export default dashboardRoutes;
